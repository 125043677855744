import React, {Component} from 'react';
import Navbar from '../components/navbar';
import HomeSocials from "../components/home-socials"
import FeaturedWork from "../components/featured-work"

class Home extends Component{

  componentDidMount(){
  }

  render(){
    return(
      <div className="homepage">
        <main className="homepage__hero">
          <Navbar />

          <div className="container"> 
            <header>
              <h1 className="homepage__title">Hi there, I'm <span className="homepage__title__name">James Walsh</span></h1>
              <h4 className="homepage__sub-title">Father, Designer, Minimalist</h4>
            </header>

            <section>
              <p>I'm a Lead Product Designer based in rainy Manchester working at <a href="https://wejo.com" target="_blank" rel="noreferrer">Wejo</a>.</p>
              <p>I have experience in developing design systems and end-to-end products for start-ups and greenfield projects. I have a passion for removing the “<i>job</i>” from everyday life and making using products painless.</p>

              <HomeSocials />
            </section>
          </div>
        </main>

        <FeaturedWork />
      </div>
    )
  }
}

export default Home
