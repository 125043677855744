import React, { Component } from 'react';
import Navbar from '../../components/navbar';
import FlowImage from "../../assets/images/work/elements-search/flow.png"
import WireImage from "../../assets/images/work/elements-search/wires.png"
import HiFiImage from "../../assets/images/work/elements-search/hi-fi.png"


class ElementsSearch extends Component {
  render() {
    return (
      <main className="workpage elements-search">
        <header className="workpage__hero">
          <Navbar />

          <div className="container">
            <h1 className="workpage__title">Elements Search</h1>
            <p className="workpage__description">A different way to search, and navigate the product</p>
          </div>
        </header>

        <div className="container">
          <section className="workpage__content-section workpage__content-section--about">
            <h2>About the project</h2>
            <p>As part of the initial core research, I found that users were struggling to find client data and navigate their current products.</p>

            <p>Because of the improvements made in surfacing data, I wanted to change the way that people thought about search.</p>

            <p><b>Making it more of a navigational point rather than the traditional functionality.</b></p>
          </section>

          <img src={FlowImage} alt="Sketch of the search flow" width="1120" height="574" />

          <section className="workpage__content-section workpage__content-section--challenges">
            <h2>UX Challenges</h2>
            <p>Search itself is a pretty basic but well known feature. What i wanted to do changes slighty what the aim of this feature is.</p>

            <p>I had to get the most important information shown whilst not detracting away from the core idea of what a search feature is. Speaking to customers they wanted a way of know what work they needed to no and for which clients.</p>

            <p>There was also a seperate method of thinking that went into just being able to find key information about a client and thier overall state in a moments notice.</p>
          </section>

          <img src={WireImage} alt="Low fidelity wireframes for the new onbaording & sign up screens" width="1120" height="574" />

          <section className="workpage__content-section workpage__content-section--solutions">
            <h2>UX solutions</h2>
            <p>Talking to users I gathered that the most important collection of information was related to upcoming tasks due today, and other clients that they have searched recently.</p>

            <p>What they also needed was a search that was global. Which allowed searching for tasks, documents and other collateral related to clients. As well as the clients themselves.</p>

            <p>The idea was to create a type of dashboard that showed this information on load. And once searched created a condensed view of the client at a moments notice. Removing the need to enter into the client's screen and increasing productivity and efficiency navigating the product.</p>
          </section>

          <img src={HiFiImage} alt="High fidelity designs for the new onbaording & sign up screens" width="1120" height="574" />
        </div>
      </main>
    )
  }
}
export default ElementsSearch
