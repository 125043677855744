import React, { Component } from 'react';
import Navbar from '../../components/navbar';
import FlowImage from "../../assets/images/work/elements-core/flow.png"
import SketchImage from "../../assets/images/work/elements-core/sketch.png"
import WireImage from "../../assets/images/work/elements-core/wires.png"
import HiFiImage from "../../assets/images/work/elements-core/hi-fi.png"


class ElementsCore extends Component {
  render() {
    return (
      <main className="workpage elements-core">
        <header className="workpage__hero">
          <Navbar />

          <div className="container"> 
            <h1 className="workpage__title">Elements Core</h1>
            <p className="workpage__description">The platform at the centre of it all</p>
          </div>
        </header>

        <div className="container">
          <section className="workpage__content-section workpage__content-section--about">
            <h2>About the project</h2>
            <p>IRIS has been the market leader in accountancy software for over 40 years, serving large practices with extensive desktop applications. As the future is cloud, we needed to design and build a new solution for the next generation of bookkeepers, accountancy firms and smaller practices.</p>
            
            <p>Initially, the plan was to simply create parity between old desktop and new cloud applications so the migration path was fast and efficient. However, with COVID-19 impacting how businesses operate, and the need to access data from anywhere, we needed to make sure the new platform was agile and scalable to cater for fast-moving government policy and the needs of our users.</p>

            <div className="workpage__callout">
              <h3>Vision</h3>
              <p>To build a cloud platform that’s scalable, easy to adopt, affordable, and a pleasure to use.</p>
            </div>
          </section>

          <img src={FlowImage} alt="Sketch of the core journey workflow" width="1120" height="574"/>


          <section className="workpage__content-section workpage__content-section--challenges">
            <h2>UX Challenges</h2>
            <p>Many IRIS software applications have been built and released in the same way for decades, using a launcher to jump into the specific product that the user has a licence for.</p> 
            
            <p>During my research, I found many similarities with the steps the user took accessing an application - finding clients, cross-referencing the record in another application, launching a task, completing the task etc. In the user interviews, there were many complaints about how difficult it was to find client information and understand which tasks to complete.</p>
            
            <p>I realised there were far too many features on the current offering that weren't needed in the new app and with some smart prioritisation we could get to market much faster than we originally anticipated. We focused on giving the users what they wanted.</p>
          </section>
          
          <img src={SketchImage} alt="Elements journey sketched wireframes" width="1120" height="574"/>

          <section className="workpage__content-section workpage__content-section--solutions">
            <h2>UX solutions</h2>
            <p>When reviewing the information architecture of the new app, I focused on removing any barriers when completing a task. I ensured we flipped from being product feature focused, to much more client-centric, with integrated modules rather than completely separate applications.</p>

            <p>I designed a centralised client view with increased data, optimised filtering, and customisable dashboards. Being able to configure their own views meant the user had everything they needed to work on a specific client and it was all in their control and reduced the need to search.</p>
          </section>

          <img src={WireImage} alt="Low fidelity wireframes for the new core screens" width="1120" height="574" />

          <section className="workpage__content-section">
            <p>When taking these wireframes through to build, the functionality was stripped back to achieve the most within the shortest period. Aiming to give the most functionality to the user without the wait.</p>
          </section>

          <img src={HiFiImage} alt="High fidelity designs for the new core screens" width="1120" height="574" />
        </div>
      </main>
    )
  }
}
export default ElementsCore
