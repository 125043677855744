import React, {Component} from 'react';
import Navbar from '../components/navbar';

class About extends Component {
  render(){
    return(
    <div> 
          <Navbar />
          I am About
      </div>
    )
  }
}
export default About
