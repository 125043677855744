import React, { Component } from 'react';
import Navbar from '../../components/navbar';
import FlowImage from "../../assets/images/work/elements-onboarding/flow.png"
import MapImage from "../../assets/images/work/elements-onboarding/map.png"
import WireImage from "../../assets/images/work/elements-onboarding/wires.png"
import HiFiImage from "../../assets/images/work/elements-onboarding/hi-fi.png"


class ElementsOnboarding extends Component {
  render() {
    return (
      <main className="workpage elements-onboarding">
        <header className="workpage__hero">
          <Navbar />

          <div className="container">
            <h1 className="workpage__title">Elements Onboarding</h1>
            <p className="workpage__description">A new login, sign up and welcome journey</p>
          </div>
        </header>

        <div className="container">
          <section className="workpage__content-section workpage__content-section--about">
            <h2>About the project</h2>
            <p>All software sold by IRIS in the past has been sold through a salesperson rather than self-serve on site.</p>

            <p>A few recent acquisitions were being integrated onto the platform and as part of this work, there was a sense to improve areas where we could. A letdown for the current platform as well as the freshly acquired ones was the sign-up and onboarding journies.</p>

            <p>The idea was to increase the conversions of customers via the website and let them engage with the platform in an alternative way, rather than the traditional account manager route.</p>
          </section>

          <img src={FlowImage} alt="Sketch of the onboarding and signup flow" width="1120" height="574" />

          <section className="workpage__content-section workpage__content-section--challenges">
            <h2>UX Challenges</h2>
            <p>Self-service trials and purchasing are a new development to IRIS customers. Usually, they would have a demo and be instructed what products fit their needs by whoever was giving that demonstration.</p>

            <p>This needed to be a focus shift into what they needed on a per-customer basis, and aim to increase lifetime value rather than a short term gain.</p>

            <p>I wanted to make sure that the user had the fewest barriers to entry so that they could engage with the application as they were thinking about it -- rather than having to wait for an account manager.</p>
          </section>

          <img src={MapImage} alt="Low fidelity ideas and journey map for onboarding and signup" width="1120" height="574" />

          <section className="workpage__content-section workpage__content-section--solutions">
            <h2>UX solutions</h2>
            <p>The onboarding itself was kept simple, removing all form fields that weren't useful and bringing in an interests element so that the platform could be tailored to the specific user.</p>

            <p>The aim of this "interests" element was to reduce the number of guides and other engagements that the user would see. So they weren't bombarded if it wasn't something they cared about.</p>

            <p>This is enabled for every user signing up not just the admin, with different engagement emails being sent related to what the specific users care about.</p>
          </section>

          <img src={WireImage} alt="Low fidelity wireframes for the new onbaording & sign up screens" width="1120" height="574" />

          <section className="workpage__content-section">
            <p>When it came down to using the platform every new customer is given 30 free days and is able to invite as many users as possible so that when the trial ends they are presented with usage data highlighting how their users have performed on the platform.</p>
            
            <p>What features, and what products have seen the most engagement, comparing this usage again to their original interests.</p>

            <p>The platform makes a recommendation based on this data so that the user can make an informed decision. Going so far as to tell people not to purchase certain things that they don't need, or extending a trial if they were unable to use that area to its full benefits.</p>
          </section>

          <img src={HiFiImage} alt="High fidelity designs for the new onbaording & sign up screens" width="1120" height="574" />
        </div>
      </main>
    )
  }
}
export default ElementsOnboarding
