import React from "react"
import {Link} from "react-router-dom"
import CoreImage from "../assets/images/work/elements-core/home-hero.png"
import SearchImage from "../assets/images/work/elements-search/home-hero.png"
import OnboardingImage from "../assets/images/work/elements-onboarding/home-hero.png"

const FeaturedWork = () => {
    const links = [
    {
      id: 1,
      url: "elements-core",
      title: "Elements Core",
      date: "2021",
      image: CoreImage,
      image_alt: ""
    },
    {
      id: 2,
      url: "elements-search",
      title: "Elements Search",
      date: "2021",
      image: SearchImage,
      image_alt: ""
    },
    {
      id: 3,
      url: "elements-onboarding",
      title: "Elements Onboarding",
      date: "2021",
      image: OnboardingImage,
      image_alt: ""
    }
  ]
  
    return (
      <div className="featured-work">
        <div className="container">
          {links.map(link => {
            return (
              <Link className="featured-work__link" to={`/work/${link.url}`} key={link.id}>
                <section className={`featured-work__item featured-work__item--${link.url}`} >
                  <img src={link.image} alt={link.image_alt} />
                  
                  <div className="featured-work__details">
                    <h2>{link.title}</h2>
                    <span>{link.date}</span>
                  </div>
                </section>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
export default FeaturedWork
