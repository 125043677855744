import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;

// const [fadeProp, setFadeProp] = useLocation();
// useEffect(() => {
//   const timeout = setInterval(() => {
//     if (fadeProp.fade === 'fade-in') {
//       setFadeProp({
//         fade: 'fade-out'
//       })
//     } else {
//       setFadeProp({
//         fade: 'fade-in'
//       })
//     }
//   }, 4000);

//   return () => clearInterval(timeout)
// }, [fadeProp])