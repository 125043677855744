import { Route, Switch } from "react-router-dom"
import './App.scss';
import Home from './routes/home';
import About from './routes/about';
import WorkCore from './routes/work/elements-core';
import WorkSearch from './routes/work/elements-search';
import WorkOnboarding from './routes/work/elements-onboarding';
import NotMatch from './routes/notmatch';
import Footer from './components/footer';

function App() {
  return (
    <>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/work/elements-core" component={WorkCore} />
        <Route path="/work/elements-search" component={WorkSearch} />
        <Route path="/work/elements-onboarding" component={WorkOnboarding} />
        {/* <Route path="/who-am-i" component={About} /> */}
        <Route path="*" component={NotMatch} />
      </Switch>

      <Footer />
  </>
  );
}

export default App;
