import React, { Component } from 'react'

class Footer extends Component {
  componentDidMount() {
    const d = new Date();
    document.getElementsByClassName("footer__copyright-date")[0].innerHTML = d.getFullYear();
  }

  render() {
    return (
      <footer className="footer">
        <div className="container">
          Copyright &copy; <span className="footer__copyright-date">----</span> James Walsh. <br />All Rights Reserved.
        </div>
      </footer>
    )
  }
}
export default Footer
