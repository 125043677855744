import React,{Component} from 'react'
import { ReactComponent as SiteLogo } from '../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';


class Nav extends Component {
  render() {
    return(
      <div className="navbar">
        <div className="navbar__container">
          <NavLink to="/" className="navbar__logo"><SiteLogo /></NavLink>

          <nav className="navbar__nav" id="cn-wrapper">
            <ul>
              <li className="navbar__item">
                <span>Work</span>
                
                <ul className="navbar__subnav">
                  <li><NavLink to="/work/elements-core" className="navbar__sub-link">Elements Core</NavLink></li> 
                  <li><NavLink to="/work/elements-search" className="navbar__sub-link">Elements Search</NavLink></li> 
                  <li><NavLink to="/work/elements-onboarding" className="navbar__sub-link">Elements Onboarding</NavLink></li> 
                </ul>
              </li> 
              {/* <li className="navbar__item"><NavLink to="/who-am-i" className="navbar__link">About</NavLink></li> */}
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}
export default Nav
