import React from "react"
import DribbbleLogo from '../assets/images/icons/dribbble.svg';
import LinkedInLogo from '../assets/images/icons/linkedin.svg';
import TwitterLogo from '../assets/images/icons/twitter.svg';

const HomeSocials = () => {
  const links = [
    {
      id: 1,
      image: DribbbleLogo,
      alt: 'Dribbble Logo',
      url: 'https://dribbble.com/Jwabbles'
    },
    {
      id: 2,
      image: LinkedInLogo,
      alt: 'LinkedIn Logo',
      url: 'https://www.linkedin.com/in/jameswalsh13/',
    },
    {
      id: 3,
      image: TwitterLogo,
      alt: 'Twitter Logo',
      url: 'https://twitter.com/Jwabbles'
    }
  ]

  return (
    <ul className="home-socials">
      {links.map(link => {
        return (
          <li key={link.id}>
            <a href={link.url} target="_blank" rel="noreferrer">
              <img src={link.image} alt={link.alt} />
            </a>
          </li>
        )
      })}
    </ul>
  )
}
export default HomeSocials
