import React, { Component } from 'react';
import Navbar from '../components/navbar';

class NotMatch extends Component {
  render() {
    return (
      <div className="error-page">
        <Navbar />
        
        <section className="error-page__section">
          <h1 className="error-page__title">404</h1>
          <p>You're in the wrong place</p>
        </section>
      </div>
    )
  }
}
export default NotMatch
